import { AxiosResponse } from "axios";

export async function downloadFile(
  resp: AxiosResponse,
  fileType: string,
  defaultFileName: string,
) {
  const data = new Uint8Array(await resp.data.arrayBuffer());
  let filename = defaultFileName;
  if (resp?.headers["content-disposition"]?.includes("filename=")) {
    filename = resp.headers["content-disposition"]
      .split("filename=")[1]
      .replaceAll('"', "");
  }
  if (data) {
    const blob = new Blob([data], { type: fileType });
    const csvUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
