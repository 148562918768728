







































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { IResult, ICOA } from "@/interfaces/index";
import { formatDate } from "@/date-utils";
import { dispatchGetResultCSV } from "@/store/main/actions";
import { downloadFile } from "@/download-utils";
import { isProd } from "@/env";

const tooltipCopyMessages = {
  ready: "Copy to clipboard",
  copied: "Copied!",
};

@Component
export default class CoaListCard extends Vue {
  tooltipCopyMsg = tooltipCopyMessages.ready;

  @Prop()
  result!: IResult;

  @Prop()
  coas!: ICOA[];

  @Prop({ default: true })
  showTestBtn!: boolean;

  formatDate(dateString: string) {
    return formatDate(dateString, null);
  }

  get loading() {
    return !this.result || !this.coas;
  }

  get testId() {
    return this.result.test_id;
  }

  get dateReported() {
    return formatDate(this.result.date_reported, "+00:00");
  }

  get testName() {
    return this.result.test_type.test_name;
  }

  get batchId() {
    return this.result.batch_id;
  }

  get resultId() {
    return this.result.id;
  }

  coaUrl(coa: ICOA) {
    return coa.public_url ? coa.public_url : "";
  }

  copyUrl(coa: ICOA) {
    let text = this.coaUrl(coa);
    navigator.clipboard.writeText(text);

    this.tooltipCopyMsg = tooltipCopyMessages.copied;
    setTimeout(() => {
      this.tooltipCopyMsg = tooltipCopyMessages.ready;
    }, 1000);
  }

  goToCoa(coa: ICOA) {
    this.$router.push({
      name: "coa",
      params: { id: this.result.id.toString(), coaId: coa.id.toString() },
    });
  }

  async downloadCSV() {
    const resp = await dispatchGetResultCSV(this.$store, {
      id: this.result.id,
    });
    if (resp) {
      downloadFile(resp, "text/csv", "test_results.csv");
    }
  }

  get showCSV() {
    return !isProd;
  }
}
