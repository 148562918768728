











































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { isProd } from "@/env";
import {
  readSample,
  readSamplePageImages,
  readSampleResults,
  readSampleResultsOptions,
  readSampleResultsPagination,
} from "@/store/main/getters";
import { formatDate } from "@/date-utils";
import {
  dispatchGetSamplePageImages,
  dispatchGetSampleResults,
  dispatchGetCompanyInfo,
  dispatchGetSampleCSV,
} from "@/store/main/actions";
import { ICompanyInfo } from "@/interfaces/index";
import { commitSetSampleResultsOptions } from "@/store/main/mutations";
import CoaListCard from "@/components/CoaListCard.vue";
import { downloadFile } from "@/download-utils";

@Component({
  components: {
    CoaListCard,
  },
})
export default class SampleLandingPage extends Vue {
  loadingImages = true;
  loadingData = true;
  companyInfo: ICompanyInfo | null = null;

  get sample() {
    return readSample(this.$store);
  }

  get results() {
    return readSampleResults(this.$store);
  }

  get sampleResultsPagination() {
    return readSampleResultsPagination(this.$store);
  }

  get totalSampleResults() {
    return this.sampleResultsPagination?.total;
  }

  get options() {
    return readSampleResultsOptions(this.$store);
  }

  set options(val) {
    commitSetSampleResultsOptions(this.$store, val);
  }

  get sampleName() {
    return this.sample?.sample_name ? this.sample.sample_name : "N/A";
  }

  get sampleId() {
    return this.sample?.sample_number ? this.sample.sample_number : "N/A";
  }

  get batchName() {
    return this.sample?.batch_name ? this.sample.batch_name : "N/A";
  }

  get samplePageImages() {
    return readSamplePageImages(this.$store);
  }

  @Watch("options")
  async onOptionsChange() {
    await this.refreshData();
  }

  showImages() {
    const matches = this.samplePageImagesMatchRoute();
    const imagesExist = this.samplePageImages?.images?.length > 0;
    return matches && imagesExist && !this.loadingImages;
  }

  samplePageImagesMatchRoute() {
    // check if the images in vuex are for our current sample
    return this.samplePageImages?.sample_id === this.$route.params.sampleId;
  }

  formatDate(dateString: string) {
    return formatDate(dateString, null);
  }

  async refreshData() {
    this.loadingData = true;

    await dispatchGetSampleResults(this.$store, {
      options: this.options,
      sample_id: this.$route.params.sampleId,
    });

    this.loadingData = false;
  }

  async getSamplePhotos() {
    if (!this.samplePageImagesMatchRoute()) {
      this.loadingImages = true;
      await dispatchGetSamplePageImages(this.$store, this.$route.params.sampleId);
    }
    this.loadingImages = false;
  }

  async getCompanyInfo() {
    this.companyInfo =
      (await dispatchGetCompanyInfo(this.$store, {
        sample_id: this.$route.params.sampleId,
      })) ?? null;
  }

  @Watch("sample")
  async onSampleChange() {
    await Promise.all([this.getSamplePhotos(), this.getCompanyInfo()]);
  }

  async mounted() {
    if (this.sample) {
      await this.onSampleChange();
    }
  }

  async downloadCSV() {
    const resp = await dispatchGetSampleCSV(this.$store, {
      sampleId: this.sampleId,
    });
    if (resp) {
      downloadFile(resp, "text/csv", "test_results.csv");
    }
  }

  get showCSV() {
    return !isProd;
  }
}
